// This is a utility function to handle an i18n where client and server $t call do not return the same thing when passed replacements
export const t = (key: string, replacements: { [key: string]: any } | null = null) => {

  // We create a fake replacement object that the original $t will use, so we are sure they remain, and we can process them ourselves
  const fakeReplacements: { [key: string]: any } = {}
  if (replacements) {
    for (let k in replacements) {
      fakeReplacements[k] = `{${k}}`
    }
  }

  let s = useNuxtApp().$i18n.t(key, fakeReplacements)
  if (replacements) {
    for (let k in replacements) {
      s = s.replace(`{${k}}`, replacements[k].toString())
    }
  }

  return s
}
